import { baseUrl } from "./Register";


export const GETAPI = async (path) => {
  try {
    const response = await fetch(`${baseUrl}${path}`, {
      method: "GET",
      headers:{'Content-Type': 'application/json'}, 
    });
    const data = await response.json();
    if (data && data.success === true) {
      return data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error); 
  }
};
export const GETAPIBYID = async (path) => {
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        headers:{'Content-Type': 'application/json'}
      });
      const data = await response.json();
      if (data && data.success === true) {
        return data.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };


  export const POSTAPI = async (path) => {
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        headers:{'Content-Type': 'application/json'}, 
      });
      const data = await response.json();
      if (data && data.success === true) {
        return data.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error); 
    }
  };