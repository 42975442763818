import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChangePosts1 } from '../Service/Reset';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';


const Reset = () => {
    const [Password, setPassword] = useState();
    const [CPassword, setCPassword] = useState();
    const [EmailId, setEmailId] = useState("");
    const [Token, setToken] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validatePasswords = (pass, confirmPass) => {
        if (pass !== confirmPass) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        validatePasswords(e.target.value, CPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setCPassword(e.target.value);
        validatePasswords(Password, e.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('EmailAddress', EmailId);
        formData.append('NewPassword', Password);
        formData.append('Token', Token);
        const response = await dispatch(ChangePosts1(formData));
        console.log(response.payload)
        if (response.payload && response.payload.message) {
            toast.success(response.payload.message);
        }
    };


    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight); 
    
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString); 
    
        const agencyId = params.get("email"); 
        const token = params.get("token");
     
        setEmailId(agencyId); 
        setToken(token); 
    }, []);
    
    return (
        <center>
            <div className="login-container">
                <div className="login-header">
                    <h2 className="forgot-password-heading">Reset Password</h2>
                    <p className="forgot-password-text">Enter your email to reset password</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input type="email" id="email" value={EmailId} name="email" placeholder="Email" required readOnly />
                    </div>
                    <div className="loginform-groupp">

                        <input type="password" id="password" name="password"
                            placeholder="Password" required onChange={handlePasswordChange} />
                        <input type="password"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            required
                            onChange={handleConfirmPasswordChange} />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>
                    <div className="form-group">
                        <button type="submit">Change Password</button>
                    </div>

                </form>
            </div>
        </center>

    );
};

export default Reset;
