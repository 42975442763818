import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const baseUrl = 'https://api.mosaicphotos.com.au/api/'


// export const baseUrl='https://192.168.29.2:8082/api/'
const initialState = {
  posts: [],
  status: "idle",
  error: null,

};
const apiUrl = `${baseUrl}Registration/AddCustomer`;

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async (body) => {
  try {
    const response = await axios.post(apiUrl, body, {
      headers: {
        "Content-Type": "multipart/form-data",

      },
    });
    return response.data;
  } catch (error) {
    // Handle errors
    console.error("Error fetching posts:", error);
    throw error;
  }
});


const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default postsSlice.reducer;
