import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'

const Create = () => {
    return (
        <>
            <Header />
            <center>
            <section class="aboutBanner"> 

		<img src="assets/img/Mosaico-Foto.webp" class="img-fluid" />
		<div class="titleBox">
			<h2>Mosaic Photo Creator</h2>
		</div>
	</section>

	<section class="galleryBox"> 
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-7 mx-auto">
					<div class="easySteps">
						<h3 class="mb-4">Four Easy to Follow Steps</h3>
						<ul>
							<li>Login or Create an Account</li>
							<li>Upload your main photo</li>
							<li>Upload your small cell photos</li>
							<li>Choose a print option</li>
						</ul>
						<a href="/upload" class="btn-start"> Let's Start </a>
					</div>
				</div>
				<div class="col-lg-5 mx-auto">
					<div class="easySteps">
						<img src="assets/img/helpdiagram.jpg" class="w-100 img-fluid" />
					</div>
				</div>
			</div>
		</div>
	</section>

            </center>
            <Footer />
        </>
    )
}

export default Create
