import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Component/Home/Home";
import Upload from "./Component/Pages/Upload";
import ImageUpload from "./Component/Pages/ImageUpload";
import Frame from "./Component/Pages/Frame";
import Test from "./Component/Pages/Test";
import Terms from "./Component/Privacy/Terms";
import Privacy from "./Component/Privacy/Privacy";
import Register from "./Component/Login/Register";
import FAQ from "./Component/Privacy/FAQ";
import Contact from "./Component/Contact/Contact";
import Account from "./Component/Account/Account";
import Login from "./Component/Login/Login";
import MyTabs from "./Component/Tab";
import Details from "./Component/FrameDetail/Details";
import Error from "./Component/Error";
import Option from "./Component/FrameDetail/Option";
import Custom from "./Component/FrameDetail/Custom";
import Confirmation from "./Component/FrameDetail/Confirmation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Forgot from "./Component/Login/Forgot";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Create from "./Component/Pages/Create";
import Sample from "./Component/Pages/Sample";
import About from "./Component/Account/About";
import PhotoGallery from "./Component/Pages/PhotoGallery";
import Preview from "./Component/Pages/Preview";
import Reset from "./Component/Login/Reset";
function App() {
  useEffect(() => {
    // Function to remove the div
    const removeLoomCompanionDiv = () => {
      // Select the div by its ID
      const loomCompanionDiv = document.getElementById('loom-companion-mv3');

      // If the div exists, remove it from the DOM
      if (loomCompanionDiv) {
        loomCompanionDiv.remove();
      }
    };

    // Call the function to remove the div
    removeLoomCompanionDiv();
  }, []); // Empty dependency array ensures this runs only once after the component mounts

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/image-upload" element={<ImageUpload />} />
        <Route path="/frame" element={<Frame />} />
        <Route path="/test" element={<Test />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/pricing" element={<Sample />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<Forgot />} />
        <Route path="/reset-password" element={<Reset />} />
        <Route path="/Faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<PhotoGallery />} />
        <Route path="/create" element={<Create />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/account" element={<Account />} />
        <Route path="/login" element={<Login />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/detail/:id" element={<Details />} />
        <Route path="/option/:id" element={<Option />} />
        <Route path="/custom/:id" element={<Custom />} />
        <Route path="/test1" element={<MyTabs />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
