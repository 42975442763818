import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../Service/Register';
import { useNavigate } from 'react-router-dom';
const Register = () => {
    const [CustomerName, setCustomerName] = useState();
    const [EmailAddress, setEmailAddress] = useState();
    const [Password, setPassword] = useState();
    const [CPassword, setCPassword] = useState();
    const [HeardAbout, setHeardAbout] = useState();
    const [Subscribe, setSubscribe] = useState(0);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        validatePasswords(e.target.value, CPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setCPassword(e.target.value);
        validatePasswords(Password, e.target.value);
    };
    const validatePasswords = (pass, confirmPass) => {
        if (pass !== confirmPass) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    };


    const handleCheckboxChange = (e) => {
        setSubscribe(e.target.checked ? 1 : 0);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('CustomerName', CustomerName);
        formData.append('EmailAddress', EmailAddress);
        formData.append('Password', Password);
        formData.append('HeardAbout', HeardAbout);
        formData.append('Subscribe', Subscribe);
        // dispatch(fetchPosts(formData));
        const response = await dispatch(fetchPosts(formData));
        if (response.payload && response.payload.message === "Customer registered successfully") {
            toast.success("Registered successfully");
            navigate("/login");
        }
        else {
            toast.error(response.payload.message)
        }
    };

    return (
        <>
            <Header />
            <center>
                <div className="signcontainer">
                    <h2 className="signh2">Create an Account</h2>
                    {/* <p className="signp">It's the easiest way to save your Mosaic Photos !</p> */}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input type="text" id="first_name" name="first_name"
                                placeholder="Name" required onChange={(e) => setCustomerName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <input type="email" id="email" name="email"
                                placeholder="Email Address" required onChange={(e) => setEmailAddress(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <input type="password" id="password" name="password"
                                placeholder="Password" required onChange={handlePasswordChange} />
                            <input type="password"
                                id="confirm_password"
                                name="confirm_password"
                                placeholder="Confirm Password"
                                required
                                onChange={handleConfirmPasswordChange} />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>

                        <div className="form-group">
                            <select id="how_hear" name="how_hear" required onChange={(e) => setHeardAbout(e.target.value)} >
                                <option value="" disabled selected hidden>How did you hear about Mosaic Photos?</option>
                                <option value="google">Google</option>
                                <option value="facebook">Friend or Family</option>
                                <option value="instagram">Facebook</option>
                                <option value="twitter">Instagram</option>
                            </select>
                        </div>
                        <div className='myDiv'>
                            <input type='checkbox' placeholder="Subscribe to our Newsletter"  onChange={handleCheckboxChange} />
                            <p>Subscribe to our Newsletter</p>
                        </div>
                        <div className="form-group">
                            <button type="submit">Create Account</button>
                        </div>
                    </form>
                    <p className="termsp">By signing up you agree to our Terms & conditions and Privacy Policy.</p>
                </div>
            </center>

            <Footer />

        </>
    )
}

export default Register
