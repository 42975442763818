import React, { useEffect } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	  
	  }, []);
    return (
        <>
            <Header />
            <center>
			<section class="aboutBanner"> 

<img src="assets/img/Mosaico-Foto.webp" class="img-fluid" />

</section>

<section class="aboutContent"> 
<div class="container">
	<div class="row">
		<div class="col-lg-10 mx-auto">
			<div class="titleBox">
				<h2>About Us</h2>
			</div>
			<div class="aboutContent-box">
				<p>Stunning mosaic photographs make unique photo gifts that will impress even the hardest critics. At Mosaic Photos Australia, we make it easy for you to take all of your favourite digital pictures and create your own personalised photomosaic.</p>
				<p>The best part, it's free for you to create a no obligations sample. Simply upload all of your favourite photos with our easy to follow ordering system and leave the rest to us.</p>
				<p>Every mosaic is different, so each one will require special attention to make it just right. Within 24 hours we will send your free sample. If you like how it looks, you can buy the full size digital copy or we can print it on one of many amazing media types.</p>
				<p>Remember, we'll keep working on your mosaic until you're 100% satisfied. It's our goal to deliver to you the perfect photomosaic at an affordable price.</p>
				<p>Check out our products page for the different ways of printing your mosaic.</p>
				<h4 class="aboutContent-heading">What is a Photographic Mosaic?</h4>
				<p>[foh-toh-moh-zey-ik] In the field of photographic imaging, a mosaic photograph (also known under the term Photomosaic, a portmanteau of photo and mosaic) is a picture that has been divided into sections, each of which is replaced with another photograph of appropriate average colour.</p>
				<p>When viewed from a distance, the individual pixels appear as the primary image, while close examination reveals that the image is in fact made up of many hundreds or thousands of smaller images. Generally they are a computer-created type of montage, but there has been some instances of larger mosaics created by hand.</p>
			</div>
		</div>
	</div>
</div>
</section>	

            </center>
            <Footer />


        </>
    )
}

export default About