import React, { useEffect } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Accordion } from '@mui/material';
const FAQ = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      
      }, []);
    return (
        <>
            <Header />

            {/* <!-- FAQ Section --> */}
            <center>
            <div className="rrow1" >
                <div className="faq-container">
                    <h4 className="faq-heading">Any Questions?</h4>
                    <Accordion className="faq-item1">

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>What is a Mosaic Photo?</h6>
                        </AccordionSummary>

                        <AccordionDetails>
                            <p>A mosaic photo (also known as a photo-mosaic)
                                is a large photo made up of smaller photos.
                                Each pixel is represented by a small photo,
                                known as the cell photo. When viewed from a
                                distance, the individual pixels appear as
                                the primary image, while close examination
                                reveals that the image is in fact made up of
                                many hundreds or thousands of smaller images.</p>
                        </AccordionDetails>

                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >    <h6>What do I get with my order?</h6>
                        </AccordionSummary>

                        <AccordionDetails>
                            <p>You will receive a personally customised mosaic photo, printed on quality photo paper or canvas. On request, we can also send the full size image. But be aware, this is a very large file.</p></AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        > <h6>100% satisfaction, guaranteed!</h6></AccordionSummary>

                        <AccordionDetails>   <p>Before any money has changed hands, we will send you a digital preview via email. If you are not 100% happy with how it looks, let us know and we will change it. Your happiness is our goal.</p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How does the order process work?</h6>
                        </AccordionSummary>

                        <AccordionDetails>
                            <p>The order process works in a few easy steps. Create an account or login. Upload your favourite photo as the primary photo. Upload a collection of photos used for the small background photos (these are your cell photos). Choose a print method (digital, paper, canvas etc) and size then submit your mosaic. Simple!</p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How many photos do I need to make up a good Mosaic?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>You could literally make a mosaic with any number of photos, but as a general rule, the more photos the better. Aim for at least 200 photos to reduce the number of duplicated cells photos. The larger the photo, the more cell photos you will need. An average Mosaic of around 16" x 20" can display up to 1500 photos, but would only need 300 photos to reduce the number of noticeable duplicates.
                                All care is taken to ensure that duplicates are spaced away from each other, so you shouldn't notice any duplicates unless you are looking hard to find them.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>Will all my photos be used?</h6>
                        </AccordionSummary>
                        <AccordionDetails>  <p>We will endeavour to use all of your supplied photos, but sometimes, some photos just don't suit the mosaic and would ruin the overall effect. In this case, we would normally remove one or two photos, but this is rare.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>When will I receive my digital preview?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Depending on the time of year, please allow up to 24 hours to receive your digital preview, but generally we will get back to you within a few short hours.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How much is delivery?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>All prices include standard domestic delivery. Express delivery and international delivery is available for some products, but beware that larger heavier items may be expensive to ship. Please contact us for a quote.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How long will it take to create my mosaic?</h6>
                        </AccordionSummary><AccordionDetails>                      <p>Digital copies will be emailed within 24 hours.
                            Photographic prints (not mounted) will be dispatched within 2 working days.
                            Canvas prints are dispacthed within 5 working days.
                            All other products may take up to 10 to 12 working days to create.
                            Please be aware that these times are just approx. After dispatch, normally delivery times apply from the East Coast.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How long does it take for delivery?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>East Coast Capital Cities: 2 to 3 days
                                West Coast and Regional Areas will take longer
                                If you would require express post, let us know and we can organsie a price.
                                Please be aware that these times are just approx and sometimes the courier services take longer than expected.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>Can I send my photos to you in the post?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Absolutely! If you are having trouble uploading photos. Please feel free to contact us and send us a USB thumb drive. If you require your USB to be sent back, let us know so we can work something out.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How are the Mosaic Photos packaged and shipped?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>The photo mosaics are packaged in a large Postpak Tube or cardboard box. They are then delivered with care via Australia Post or a Local Courier.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>Should I crop and prepare my cell photos?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>We will crop all cell photos to a similar sized rectangle. If you have a lot of square or panaramic photos or cell photos with white borders, we highly recommend you crop your photos the way you would like to see them displayed. If you prefer, for a small fee, we can crop each of your photos to what we think is best.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>What kind of photos is best for a mosaic?</h6>

                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Generally a photo with as little detail as possible. If you can imagine, the detail of the photo is made up of smaller cell photos and if the cell photo is bigger than the detail it is covering, the detail will be lost.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>How do I pay for my Mosaic?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Once you upload your photos, we will send you a sample of your mosaic. When you are 100% happy with your order, click on the link within the email to pay for your new mosaic.
                                Our website uses PayPal for all payments. You do not need to have an account with PayPal, but it does help. If you do not have an account with PayPal, you can use the credit card facility that PayPal provides to pay for your mosaic. PayPal is the safest and easiest way to pay online without revealing your credit card number.</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="faq-item1">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6>Still can't find what you're looking for?</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Please Contact Us if you have any questions not addressed on this page.</p>
                        </AccordionDetails>
                    </Accordion>
                </div>
                </div>
            </center>


            <Footer />
        </>
    )
}

export default FAQ
