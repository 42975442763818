import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoginPosts } from '../Service/Login';
import { toast } from 'react-toastify';

const Login = () => {
    const [EmailAddress, setEmailAddress] = useState();
    const [Password, setPassword] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('UserName', EmailAddress);
        formData.append('Password', Password);
        const response = await dispatch(LoginPosts(formData));
        console.log(response.payload)
        if (response.payload && response.payload.message === "User login successfully") {
            toast.success("Logged In successfully");
            localStorage.setItem('token', response.payload.data[0].Token)
            localStorage.setItem('Id', response.payload.data[0].CustomerId)
            if (localStorage.getItem('submittingOrder')) {
                navigate("/confirmation");
            }
            else {
                navigate("/account");
            }
        }
        else {
            toast.error(response.payload.message)
        }
    };
    return (
        <>

            <center>
                <div className="login-container">
                    <div className="login-header">
                        <h2 className="signh2">Login to Mosaics Photos</h2>
                        <p className="signp">Login to access your Mosaic Photos account, view your orders and saved designs</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input type="email" id="email" value={EmailAddress} name="email" placeholder="Email" required onChange={(e) => setEmailAddress(e.target.value)} />
                        </div>
                        <div className="loginform-groupp">
                            <input type="password" id="password" value={Password} name="password" placeholder="Password" required onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <button type="submit">Login</button>
                        </div>
                        <p className="termsp"> Don't have an account? <a href="/register"> Click here </a> to create one now! <br />
                            <Link to={'/forgot-password'}>Forgot Password? </Link>
                        </p>
                    </form>
                </div>
            </center>

        </>
    )
}

export default Login
